<script>
     
    import Log from '../util/Log.js'
    import {Button, Col, Container, Row, Form, FormGroup, FormText, Input, Label, Media } from 'sveltestrap';
    import Lazy from "./lazy/Lazy.svelte"
    import Gallery from "./Gallery.svelte";
    import Gamepad from "./Gamepad.svelte";
    import Chat from "./Chat.svelte";
	import { slide } from 'svelte/transition';
	import { quintOut } from 'svelte/easing';
	import { eventStore, deviceStore, nickname, loggedIn, referURL } from './stores.js';
	import { onMount } from 'svelte';
    import { onDestroy } from 'svelte';
    import { navigate } from "svelte-routing";  
    
     
    export let id;
    export let cat;
    
    let eventbus_url = __prezzence_web.env.api_eventbus;
    
    let status;
    
    let images=[];
    let columns = 4;
    let maxItems=20;
    let playing = false;
    let buttonText = "Start";
    let buttonTextSession = "Request Session";
    let buttonTextSessionStop = "Session stopped";
     
    let visibility="block";
    let requestSend = false;    
    let items="";
    //let address = "event/status";
    	
    const logger = new Log(this);
    
    //Event Bus Message
    var ebm; 
    let eb;
    
    let uid="";    
    let sessionId="";
    let commandAddress = "event/command/";
    let sessionRequest = "event/session/request/"
    let sessionLeader = false;
     
    let description;
    let title;
    let instruction;
    let since;
    let location;
    let devices;
    let startDate;
    let activated;

    //Video
    var flvPlayer = null;     

    let visText = "Hide Gamepad"; 
    let visibleGamepad = true;

    let colInfoVisible = true;
    
//     let saveNickNameButtonText = "Edit";
    
    
    //Test for loggedIn
    //console.log("$loggedIn: " + $loggedIn);
    if($loggedIn<1){
        //navigate("", { replace: true });
        //console.log("$referURL: " + $referURL);    
        window.location.href = "/"; 
    }
    
    
    
    
        
    const toggleGamepad = () => {
        visibleGamepad = !visibleGamepad
        visText = visibleGamepad?"Hide Gamepad":"Show Gamepad";
    };

    function wait(ms){
       var start = new Date().getTime();
       var end = start;
       while(end < start + ms) {
         end = new Date().getTime();
      }
    }

    function mapImages(){
    
        logger.log("Detail->cat: "+cat);
        
        if(cat=="event"){
        
            if($eventStore!=""){
                items = JSON.parse($eventStore);
            }else{
                return false;
            }
            
        }else{

            if($deviceStore!=""){
                items = JSON.parse($deviceStore);
            }else{
                return false;
            }

        }
 
        logger.log("Items from store: "+items);

            items.map(function(item, index, array) {

                if(item.id==id){

                    logger.log("item found: "+item.id + ": Activated "+item.activated);

                    title = item.title;
                    description = item.description;
                    instruction = item.instruction;
                    location =  item.location;
                    since = item.since;
                    activated = item.activated;
                    startDate = "No";

                    if(cat=="event"){
                        devices = item.devices;
                        startDate = item.eventDate;
                    }else {
                        devices = "";
                    }

                    if(index<maxItems){
                        for(let i = 0; i < item.images.length; i++) {
                            images.push("../"+item.images[i]);
                        }
                    }
                }
            });


    
    
    }
    
    
    function toggleSession(event){
    
            buttonTextSession = playing?buttonTextSessionStop:buttonTextSession;
            
            if(playing){ 
              
                //console.log("Registering Session handler"); 
                //Register on push button
                //registerSession();
                visibleGamepad = !visibleGamepad;
                visText = visibleGamepad?"Gamepad hide":"Gamepad show";

            }else {
                
                if(eb){
                    
                    //console.log("Unregistering Session handler");
                    
                    //Not working....
//                     eb.unregisterHandler("event/session/"+id+"/"+uid, function(error, message) {
//                         console.log("unregisterHandler error: "+error); 
//                         console.log("unregisterHandler: "+ message); 
//                     });
                    
                    //Workaround
                    sessionId="";
                    eb.close();
                    setupEventBus();
                
                }else{
                    console.log("Unregistering EB null"); 
                }         
            }
    }
    
    function toggleStart(event){
        
        //console.log(event.detail);
        //send a message
        //eb.send('device.status', {name: 'state', value:'Running'});
        
        //Start:Stop video
        if(!playing){
            startVideo("https://www.prezzence.net/live?app=prezzence&stream=mjopikawegGAGA"); 
        }else{
           stopVideo();
        }
        
            
        if(event.detail != 0) {
            
            if(sessionLeader){
                sessionLeader = false;
                buttonTextSession = playing?buttonTextSessionStop:buttonTextSession;
            }
            
            if(!playing){
                sessionLeader = false;
            }
            
            playing = !playing;
            //reload images to display in gallery
            !playing?mapImages():null;
            
            buttonText = playing?"Stop":"Start"; 
            
            //Hide info col_info
            colInfoVisible = !colInfoVisible;
            
             
        }
    }
    
    
   function setupEventBus() {
        
        //console.log("Eventbus setup");
        
        //eb = new EventBus('http://192.168.0.3:8082/eventbus', {"vertxbus_ping_interval": 300000}); // sends ping every 5 minutes.
        
        eb = new EventBus(eventbus_url, {"vertxbus_ping_interval": 3000}); // sends ping every 1 minute.
        
        eb.enableReconnect(true);
        
        if(!uid){
            uid = makeUUID();
        }
        
        commandAddress = commandAddress.concat(id);
        commandAddress = commandAddress.concat("/");
        commandAddress = commandAddress.concat(uid);
        
        sessionRequest = sessionRequest.concat(id);
        sessionRequest = sessionRequest.concat("/");
        sessionRequest = sessionRequest.concat(uid);
        
        eb.onclose = function(e){
            //console.log("EB closed");
        };
    
        eb.onopen = function () {
           
            //console.log("EB opened");
            
            registerStatus();
            registerSession();
            
            //console.log("Registered Status Handler");
        };
                
    }
   
    function registerStatus(){
   
        if(eb){
         
            //console.log("event/status/event2");
            //console.log("registerstatus id: "+ id);
            
            
            eb.registerHandler("event/status/event2", function(error, message) {
            //eb.registerHandler("event/status/"+id, function(error, message) {
                
                //console.log(message.body);
                
                if(message.body!=null){
                    //console.log(message.body);  
                    
                    let body = JSON.parse(message.body);

                    status = body.data.state; 
                    
                    //console.log(body.data.state);    
                      
                }else{
                    let body = JSON.parse(message.body); 
                    status = message.body; 
                }
            
            });
        }
        
    }
   
//     function registerCommand(){
//    
//         if(eb){
//         
//             console.log("event/status/event2");
//         
//             eb.registerHandler("event/command/"+id+"/"+uid, function(error, message) {
//                 
//                 if(message.body!=null){
//                     console.log(message.body); 
//                     
//                     let body = JSON.parse(message.body);
// 
//                     status = body.data.state; 
//                     
//                     console.log(body.data.state);    
//                       
//                 }else{
//                     let body = JSON.parse(message.body); 
//                     status = message.body; 
//                 }
//             
//             });
//         }
//         
//     }
    
    function requestSession(){
        
        //jSessionRequest = { "event":id, "userid":uid };
        //let sessionRequest = "event/session/request/"+id+"/"+uid;
        //console.log("Send requestSession: "+sessionRequest);
        if(requestSend){
            console.log("Request allready send");
        }else{
            eb.send(sessionRequest,sessionRequest);
            requestSend = true;
        }
        
        
        
    }
    
    function registerSession(){ 
   
        if(eb){
        
           //console.log("Register Session for: "+"event/session/"+id+"/"+uid); 
           
           //id  = eventId
           //uid = browserId
         
           //console.log("Register Session Handler: "+sessionRequest);
           
           eb.registerHandler(sessionRequest, function(error, message) {
           //eb.registerHandler("event/session/"+id+"/"+uid, function(error, message) {
                    
                //console.log("session error: "+error); 
                //console.log("Session register return message: "+ message); 
                  
                //console.table(message);
                 
                //let jSession = JSON.parse(message.body);
                let sessionStatus = null;
                let body;
                
                //session is requested 
                if(hasJsonStructure(message.body)){
                    body = JSON.parse(message.body);
                }else {
                    body = message.body;
                }
                
                let address = message.address;
                 
                if(body==address){
                    buttonTextSession = "Session requested";
                    sessionLeader = false; 
                    requestSend = true;
                }else if(body.session_state=="stop"){
                    buttonTextSession = "Request new session";
                    sessionLeader = false;
                    requestSend = false;
                }else if(body.session_state=="start"){
                    buttonTextSession = "You have control";  
                    sessionLeader = true;
                    requestSend = true;
                }else{
                    buttonTextSession = "Request Session";
                    sessionLeader = false;
                    requestSend = false;
                }
                
                //console.log("sessionLeader: "+sessionLeader);
                
            });
        }
    }

    
    function hasJsonStructure(str) {
        if (typeof str !== 'string') return false;
        try {
            const result = JSON.parse(str);
            const type = Object.prototype.toString.call(result);
            return type === '[object Object]' 
                || type === '[object Array]';
        } catch (err) {
            return false;
        }
    }

    
    
    
    onMount(() => {
         
        //console.log("OnMount Start");
        
        
         
        //logger.log("items: "+items );
        setupEventBus(); 

        
        
        //console.log("OnMount End");
         
	});
	
	onDestroy(() => {
        
        if(eb){
            //console.log("EB closed");
            eb.close();
        } 
        
		//console.log('Component destroyed');
	});
	
    function makeUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (a, b) {
                return b = Math.random() * 16, (a == 'y' ? b & 3 | 8 : b | 0).toString(16);
            });
    }

    function playVideo(){
        //console.log(flvPlayer);
        flvPlayer.play();    
    }
    
    
    let stopVideo = function() {
        
        if(flvPlayer===null){ 
            console.log("flvPlayer === null");
        }else{
            flvPlayer.pause();
            flvPlayer.unload();
            flvPlayer.detachMediaElement();
            flvPlayer.destroy();
            flvPlayer = null;
        }
    }
    
    
    function startVideo(mUrl){
    
        if(flvjs.isSupported()) {
        
            var videoElement = document.getElementById("videoElement");
            
            //console.log(videoElement);
            
            flvPlayer = flvjs.createPlayer({
                type: 'flv',
                isLive: true,
                duration: 100,
                url: mUrl
            },{
                enableStashBuffer:false    
            });
            
            flvPlayer.on(flvjs.ErrorTypes.NETWORK_ERROR, stopVideo);
            
            flvPlayer.attachMediaElement(videoElement);
            
            try {          
                flvPlayer.load();
                flvPlayer.play(); 
            }catch(e){
            
                
            
                console.log(e);
                
                
            }
        }
    }
    
    
    
    function makeChatUUID() {
        return 'xxxxxxxx'.replace(/[xy]/g, function (a, b) {
                return b = Math.random() * 8, (a == 'y' ? b & 3 | 8 : b | 0).toString(8);
            });
    }
    
    mapImages();

    const imgContent = document.querySelectorAll('.img-content'); 

    function showImgContent(e) {
        for(var i = 0; i < imgContent.length; i++) {
            x = e.pageX;
            y = e.pageY;
            imgContent[i].style.transform = `translate3d(${x}px, ${y}px, 0)`;
        }
    };

    document.addEventListener('mousemove', showImgContent);
    
    
    
    
    
</script>

     

<style>
 
  /*  card {
        max-width: 100px;
        max-hight: 200px;
    }*/
 
 /*   body {
        background-color:orange ; 
        color: white;
    }*/
     
    /*main {
        
    }*/
    
    #videoElement {
        min-width:400px;
        width:800px;
        
    }

</style>  

<main>
    
    
       
    <div class="container mx-auto w-auto m-10 p-5">
        <div class="row m-3">
            <div class="col-3">
               
               <Chat/>
               
            </div>
            <div class="col-9">
            
                <div class="row">
            
                    <div id="col_info" class={colInfoVisible?"col d-block":"col d-none"}>
                        
                            <!--<p>ID:{id}</p>-->
                            
                            <div><b>Title:</b> {title}</div>
                            <div><b>Description:</b> {description}</div>
                            <div><b>Instruction:</b> {instruction}</div>
                            <div><b>Devices:</b> {devices}</div>
                            <div><b>Location:</b> {location}</div>
                            <div><b>Since:</b> {since}</div>
                            <div><b>Active:</b> { startDate} </div>
                            <!--<p>Crates&Cranes</p>-->
                            <!--<p>Put the crate in other place. Thats all!</p>-->
                            <!--<p>Use the forward, up/down and reach functions</p>           -->
                            {#if activated}
                                <div><b>Connection State:</b>{status==null?" Waiting for response...":status}</div>
                            {/if}
                    </div>
                </div>
                <div class="row">

                    {#if activated}
                        <div class="col">

                                <div class="row justify-content-center" >
                                    <div class="col-m-1">
                                        <Button color="primary" on:click={toggleStart}>{buttonText}</Button>

                                    {#if playing}
                                    <!--<input class="btn btn-primary" on:click|preventDefault={requestSession} type="button" value="Request Session">-->
                                        <Button color={requestSend?"primary":"danger"} id="session_request" on:click={requestSession}>{buttonTextSession}</Button>

                                    {/if}

                                    {#if sessionLeader}
                                        <Button color="secondary" on:click={toggleGamepad}>{visText}</Button>
                                    {/if}
                                    </div>

                                </div>


                                {#if playing}
                                    {#key sessionLeader }
                                        {#if sessionLeader}

                                                <Gamepad visible={visibleGamepad} eb={eb} commandAddress={commandAddress} sessionLeader={sessionLeader}></Gamepad>

                                        {/if}
                                    {/key}
                                {/if}
                        </div>
                    {/if}

                </div>
        
        
                <div class="row m-3">
                    <div class="col">
                        {#if !playing}
                            {#each {length: (images.length/columns)} as _, x}
                                <Row >
                                    {#each {length: columns} as _, n}
                        
                                        {#if images.length>((x*columns)+n) }
                                            <Col lg="auto" class="column-xs-2 column-md-2">  
                                                <img alt="" class="img-content w-150 shadow-2-strong rounded mb-4" src={images.shift()} />  
                                                <!--<img alt="" class="card" src={images.shift()} />  -->
                                            </Col>
                                        {/if}
                                        
                                    {/each}  
                                </Row>
                            {/each}                            
                        {/if}
                    </div>
                </div>
                
                <div class="row justify-content-center">  
                    
                    
                    <video id="videoElement">
                        <track kind="captions">
                    </video>     
                    
                </div>    
            </div>
          
    </div>
            
                       
      
</main>


