<script>

    
    import { Container }   from "sveltestrap";
    import Banner          from "../components/Banner.svelte";
    import Gallery         from "../components/Gallery.svelte";
    import { loggedIn, username, eventStore, deviceStore,lastRefresh } from  "../components/stores.js";
    import Log from '../util/Log.js'
    import { onMount } from 'svelte';
    
    const logger = new Log(this); 
        
    let bannerText = "Welcome to Prezzence";
    let bannerVisible = true;
    
    let api_event  = __prezzence_web.env.api_events;
    let api_device = __prezzence_web.env.api_devices;
    
</script>    

<main>
    
    <Banner {bannerVisible}>
        <span slot="bannerText">
            Welcome
                {#if $username!=0 } 
                    { $username }
                {/if}  
        </span>
        <span slot="bannerSubText">
            <p>Manipulate Reality... Really!</p>
        </span>
    </Banner>
    
    {#key $loggedIn}
        <Gallery maxItems={4} cat="event" url={api_event}></Gallery>
    {/key}
    
</main>    
    
 

<style>
    body {
        
    }
    
	main {
		text-align: center;
		padding: 1em;
		max-width: 310px;
		margin: 0 auto;
		margin-top: 40px;
	}

	h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>

