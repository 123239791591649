class Log {

    
    
    constructor(self) {

        if(__prezzence_web.env.environment === "dev"){
            //console.log("Environment: "+__prezzence_web.env.environment);
        }
        
        this.self = self;
    }
     
  
//   constructor(self, text) {
//     
//     if(__prezzence_web.env.environment. === "dev"){
//         
//         if(self!=null){
//             console.log(self[5].name+": "+text);              
//         }else{
//             console.log(text);              
//         }
//         
//     }
//     
//   }

    log(text){
        
        if(__prezzence_web.env.environment === "dev"){
            
            console.log(self);
            
            if(this.self!=null){
                console.log(self[5].name+": "+text);              
            }else{
                console.log(text);              
            }
            
        }
        
    }

  
} 

export default Log;
