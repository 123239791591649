<script>
  import {
    Button,
    Collapse,
    Container,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
  } from 'sveltestrap';

  import { Link } from "svelte-routing";
  import Login from './Login.svelte';
  import Register from './Register.svelte';
  import { loggedIn, username, referURL } from './stores.js';
  import { onMount, onDestroy } from 'svelte';
  import Log from '../util/Log.js';

  const logger = new Log(this);
  let url = location.href;
  let isOpen = false;
  const color = "light";
  let version_client = "?";

  function handleUpdate(event) {
    isOpen = event.detail.isOpen;
   
  }

  function getVersion(){
    fetch("/api/v1/data")
        .then(response => response.json())
        .then(data =>  {
            version_client = data.version_client;
        })
        .catch((error) => {
            logger.log('Error:'+error);

        });
  }

  function updateUrl(){
        $referURL = window.location.href;
  }
    
onMount(() => {
    getVersion();
});

</script>
        
    <Navbar class="fixed-top" color={color} light expand="lg">
        <Container>
            <NavbarBrand>Prezzence</NavbarBrand><div class="small">version {version_client}</div>

            <NavbarToggler on:click={() => (isOpen = !isOpen)} />
            <Collapse {isOpen} navbar expand="md" on:update={handleUpdate}>
                <Nav class="ml-auto" navbar>
                    <NavItem> 
                        <NavLink href="" on:click={updateUrl}><Link to="events">Events</Link></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="" on:click={updateUrl}><Link to="devices">Devices</Link></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="" on:click={updateUrl}><Link to="docs/faq">FAQ</Link></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="" on:click={updateUrl}><Link to="about">About</Link></NavLink>
                    </NavItem>
                    <NavItem>
                        <Login/>
                    </NavItem>
                    <!--<NavItem>
                        <Register/>
                    </NavItem>--> 
                </Nav>
            </Collapse>
        </Container>
    </Navbar>
        

