<script>

    import Banner from "../components/Banner.svelte";
    import { Button, Container, Row, Col } from "sveltestrap";
    import { Form, Field, ErrorMessage,Textarea } from "svelte-forms-lib";
    import * as yup from "yup";

    let bannerVisible = true;
    let url_about_post_question = __prezzence_web.env.api_about_post_question;
    let sendResult = "";
    
    const formProps = {
              initialValues: { name: "", email: "", message: "" }, 
              validationSchema: yup.object().shape({
                name: yup.string().required(),
                email: yup
                  .string()
                  .email() 
                  .required(),
                message: yup.string().required()
              }),
              onSubmit: values => {
                values.created =  Date.now(); 
                send(values); 
              }
            }; 
    
    function getCookieXSRFValue(){
        return document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN')).replace("XSRF-TOKEN=","");
    }
     
    function send(values){
        
        //console.log(values);
        
        fetch(url_about_post_question, {
                method: 'POST',
                headers: {
                    'X-XSRF-TOKEN': getCookieXSRFValue(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            })
            .then(response => response.json())
            .then(data => {
                console.log('Result:'+data.result);               
                
                if(data.result=="OK"){
                    
                    sendResult = "Message received. Thank you.";
                    
                    setTimeout(function(){
                        sendResult = "";
                    }, 5000); 
                }else {
                    sendResult = data.message;
                    
                    setTimeout(function(){
                        sendResult = "";
                    }, 5000); 
                     
                    
                }

            })
            .catch((error) => {
                console.log('Result:'+error);
                //$form.token = error.token;
            });    
        
    }
    
    
</script>

<style>

    body {
        
    }
    
	main {
		text-align: center;
		padding: 1em;
		max-width: 310px;
		margin: 0 auto;
		margin-top: 40px;
	}

	h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}

    :global(.form-field) {
/*              font-family: monospace;*/
              padding: 5px;
              margin: 5px;
              border: none;
              border-radius: 0;
              background: #1e2145; 
              color: #fff;
    } 
    
    :global(.form-field:focus) {
        border-color: #5be2a9;
        box-shadow: 0 0 0 5px #5be2a9;
    }
     
    :global(.form-error) {
        /*font-family: monospace;*/
        color: #1e2145;
    } 
         
</style>

<main>
    
    <Banner {bannerVisible}>
        <span slot="bannerText">
            About Prezzence
        </span>
        <span slot="bannerSubText">
            <p>Prezzence (as in "presence") is all about controlling devices in a remote place.</p>
        </span>
    </Banner>
         
    <Container>
        <span>  
            <p>If you want a demo login or have a question, please send us<br> 
            a message and we will contact you asap.</p>
            
            
        </span>
        <div>  
            <span>{sendResult}</span>
        </div>
        <Form {...formProps}>   
            <div class="row">   
                <div class="col">  
                    <div>  
                        <ErrorMessage class="form-error" name="name" />
                    </div> 
                    <div> 
                        <Field class="form-field" name="name"  size="30" placeholder="Name" />
                    </div> 
                    <div>  
                        <ErrorMessage class="form-error" name="email" />
                    </div>
                    <div>  
                        <Field class="form-field" name="email" size="30" placeholder="Email"/>
                    </div> 
                    <div>  
                        <ErrorMessage class="form-error" name="message" /> 
                    </div>
                    <div>    
                        <Textarea class="form-field" cols="30" rows="5" name="message" placeholder="Message"/> 
                    </div> 
                    <div style="margin:10px">
                        <Button color="primary" type="submit">Send</Button>
                    </div>   
                    
                </div>
            </div>  
        </Form>
     
   
   </Container>
    
    
</main>
