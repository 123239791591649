<script>

    import { Button, Col, Container, Row } from 'sveltestrap';
    import { onMount, onDestroy, afterUpdate, beforeUpdate } from 'svelte';

    export let eb;
    export let commandAddress;
    export let sessionLeader = false;
    
    export let visible = true;
    
    
    var commandGPAS = "GPAS" //GamePadAllStop    
    var commandGPMS = "GPMS" //GamePadMoveStop
    var commandGPCS = "GPCS" //GamePadCraneStop
    var commandGPHS = "GPHS" //GamePadHookStop
    
    var commandGPMF = "GPMF" //GamePadMoveForward
    var commandGPMB = "GPMB" //GamePadMoveBackward
    var commandGPML = "GPML" //GamePadMoveLeft
    var commandGPMR = "GPMR" //GamePadMoveRight
    
    var commandGPCU = "GPCU" //GamePadCraneUp
    var commandGPCD = "GPCD" //GamePadCraneDown
    
    var commandGPHA = "GPHA" //GamePadHookAction
    
    onDestroy(() => {
        sessionLeader = false; 
    });
            
    onMount(() => {
        console.log("OnMount: "+ eb);
        addEventListeners();
	});
    
    function addEventListeners(){
        
         window.addEventListener('keydown', function(e) {
            if(e.keyCode == 32 && e.target == document.body) {
                e.preventDefault();
            }
        });
  
        document.addEventListener('keydown', buttonFiredKeys, false);
        document.addEventListener('keyup', buttonFiredKeys, false);

        const gbmf = document.getElementById("gamepad_button_move_forward");
        gbmf.addEventListener('mousedown', buttonFiredMouse, false);
        gbmf.addEventListener('mouseup', buttonFiredMouse, false);   
        
        const gbmb = document.getElementById("gamepad_button_move_backward");
        gbmb.addEventListener('mousedown', buttonFiredMouse, false);
        gbmb.addEventListener('mouseup', buttonFiredMouse, false);
  
        const gbml = document.getElementById("gamepad_button_move_left");
        gbml.addEventListener('mousedown', buttonFiredMouse, false);
        gbml.addEventListener('mouseup', buttonFiredMouse, false);   
        
        const gbmr = document.getElementById("gamepad_button_move_right");
        gbmr.addEventListener('mousedown', buttonFiredMouse, false);
        gbmr.addEventListener('mouseup', buttonFiredMouse, false);   
              
        const gbcu = document.getElementById("gamepad_button_crane_up");
        gbcu.addEventListener('mousedown', buttonFiredMouse, false);
        gbcu.addEventListener('mouseup', buttonFiredMouse, false);

        const gbcd = document.getElementById("gamepad_button_crane_down");
        gbcd.addEventListener('mousedown', buttonFiredMouse, false);
        gbcd.addEventListener('mouseup', buttonFiredMouse, false);   

        const gbha = document.getElementById("gamepad_button_hook_action");
        gbha.addEventListener('mousedown', buttonFiredMouse, false);
        gbha.addEventListener('mouseup', buttonFiredMouse, false);   
        
    }
    
    function buttonFiredKeys(event){
        
  
        if(event.target.id==="chatInput" || event.target.id==="chatNickName"){
            console.log("ChatInput/Chatname not triggering action: "+event.target.id)    
            return;
        }
        
        if(event.repeat) { 
            console.log("Event repeated");
            return;
        }
         
        switch(event.keyCode){
         
            case 87:
                console.log("Forward action (w) pressed"); 
                if(event.type=="keydown"){ 
                    sendCommand(commandGPMF); 
                }else if(event.type=="keyup"){  
                    sendCommand(commandGPMS);
                }
                
                break;
            case 83:
                console.log("Backward action (s) pressed");
                if(event.type=="keydown"){ 
                    sendCommand(commandGPMB);
                }else if(event.type=="keyup"){  
                    sendCommand(commandGPMS);
                }
                break;
            case 65:
                console.log("Left action (a) pressed");
                if(event.type=="keydown"){ 
                    sendCommand(commandGPML);
                }else if(event.type=="keyup"){  
                    sendCommand(commandGPMS);
                }
                
                break;
            case 68:
                console.log("Right action (d) pressed");
                if(event.type=="keydown"){ 
                    sendCommand(commandGPMR);
                }else if(event.type=="keyup"){  
                    sendCommand(commandGPMS);
                }
                
                break;
            case 81:
                console.log("gamepad_button_crane_up (q) pressed");
                if(event.type=="keydown"){ 
                    sendCommand(commandGPCU);
                }else if(event.type=="keyup"){  
                    sendCommand(commandGPCS);
                } 
                break;
            case 69:
                console.log("gamepad_button_crane_down (e) pressed");
                if(event.type=="keydown"){ 
                    sendCommand(commandGPCD);
                }else if(event.type=="keyup"){  
                    sendCommand(commandGPCS);
                }  
                break;
            case 32:
                console.log("Hook action (space) pressed");
                if(event.type=="keydown"){ 
                   sendCommand(commandGPHA);
                }else if(event.type=="keyup"){  
                   sendCommand(commandGPHS);
                }               
                break;
            default:
                console.log("Key not found")
                sendCommand(commandGPAS);
                break;
        }
    }
    
    
    function buttonFiredMouse(event){

        //This prevents double events fired when button has focus and is triggered
        if(event.detail != 0) {
            
            if(event.repeat) { 
                return;
            }
   
            //console.log("Id"+event.target.id)

            switch(event.target.id){
                
                case "gamepad_button_move_forward":
                    if(event.type=="mousedown"){ 
                        sendCommand(commandGPMF);
                    }else if(event.type=="mouseup"){  
                        sendCommand(commandGPMS);
                    }

                    break;
                case "gamepad_button_move_backward":
                    if(event.type=="mousedown"){ 
                        sendCommand(commandGPMB);
                    }else if(event.type=="mouseup"){  
                        sendCommand(commandGPMS);
                    }

                    break;
                case "gamepad_button_move_left":
                    if(event.type=="mousedown"){ 
                        sendCommand(commandGPML);
                    }else if(event.type=="mouseup"){  
                        sendCommand(commandGPMS);
                    }
                    break;
                case "gamepad_button_move_right":
                    if(event.type=="mousedown"){ 
                        sendCommand(commandGPMR);
                    }else if(event.type=="mouseup"){  
                        sendCommand(commandGPMS);
                    }
                    break;
                case "gamepad_button_crane_up":
                    if(event.type=="mousedown"){ 
                        sendCommand(commandGPCU);
                    }else if(event.type=="mouseup"){  
                        sendCommand(commandGPCS);
                    }
                    break;
                case "gamepad_button_crane_down":
                    if(event.type=="mousedown"){ 
                        sendCommand(commandGPCD);
                    }else if(event.type=="mouseup"){  
                        sendCommand(commandGPCS);
                    }
                    break;
                case "gamepad_button_hook_action":
                    if(event.type=="mousedown"){ 
                        sendCommand(commandGPHA);
                    }else if(event.type=="mouseup"){  
                        sendCommand(commandGPHS);
                    }
                    break;
                
                default:
                    console.log("Button not found");
                    sendCommand(commandGPAS);
                    break;
            }
        }
    }
    
    function sendCommand(commandId){
        
        /*
            var commandGPAS = "GPAS" //GamePadAllStop
            var commandGPMS = "GPMS" //GamePadMoveStop
            var commandGPCS = "GPCS" //GamePadCraneStop
            var commandGPHS = "GPHS" //GamePadHookStop
        
            var commandGPMF = "GPMF" //GamePadMoveForward
            var commandGPMB = "GPMB" //GamePadMoveBackward
            var commandGPML = "GPML" //GamePadMoveLeft
            var commandGPMR = "GPMR" //GamePadMoveRight
            
            var commandGPCU = "GPCU" //GamePadCraneUp
            var commandGPCU = "GPCD" //GamePadCraneDown
            
            var commandGPHA = "GPHA" //GamePadHookAction
        */
        
        var command = commandGPAS; 
        
        switch(commandId){
            case "GPAS":
                command = commandGPAS;
                break;
            case "GPMS":
                command = commandGPMS;
                break;
            case "GPCS":
                command = commandGPCS;
                break;
            case "GPHS":
                command = commandGPHS;
                break;
            
            case "GPMF":
                command = commandGPMF;
                break;
            case "GPMB": 
                command = commandGPMB;
                break;
            case "GPML": 
                command = commandGPML;
                break;
            case "GPMR": 
                command = commandGPMR;
                break;
            
            case "GPCU": 
                command = commandGPCU;
                break;
            case "GPCD": 
                command = commandGPCD;
                break;
            
            case "GPHA": 
                command = commandGPHA;
                break;
            
            default: 
                break;
        }
                
        if(sessionLeader){
            eb.send(commandAddress,command);
            console.log("Command send: "+command);
            console.log("sessionLeader: "+sessionLeader);
        }else{
            console.log("Command not send: "+command);
            console.log("sessionLeader: "+sessionLeader);
        }
        
        
        
    }

    
    
    
</script>


<main>
    
    <slot></slot>
    
    <Container>
        <center> 
            
        <div id="gamepad" class={visible?"gamepad visible":"gamepad invisible"}>
        
            <div class="row xl-2">
                <div class="col">
            
                    <div>  
                        <Button color="info" class="gamepad_button" id="gamepad_button_move_forward">Forward (w)</Button>
                    </div>  
                    <div>
                        <Button color="info" class="gamepad_button" id="gamepad_button_move_left">Left (a)</Button>
                        <Button color="info" class="gamepad_button" id="gamepad_button_move_right">Right (d)</Button>
                    </div> 
                    <div>    
                        <Button color="info" class="gamepad_button" id="gamepad_button_move_backward">Backward (s)</Button>
                    </div>
                    
                </div>
                <div class="col">
                    <div> 
                        <Button color="warning" class="gamepad_button" id="gamepad_button_crane_up">Crane Up (q)</Button>
                    </div>                                           
                    <div> 
                        <Button color="warning" class="gamepad_button" id="gamepad_button_crane_down">Crane Down (e)</Button>
                    </div>
                    <div>
                        <Button color="warning" class="gamepad_button" id="gamepad_button_hook_action">Hook (space)</Button>
                    </div>
                </div>
            </div>
        </center>
    </Container>


</main>



<style>

    .gamepad div {
        padding:10px;
        
    }

</style>




