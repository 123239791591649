import { writable } from 'svelte/store'

let lastRefresh;

//= () => {Date.getTime()};
const setLastRefresh = () => { lastRefresh = new Date().getTime() } ;

const chatsStore = writable(sessionStorage.getItem("chatsStore") || "");
chatsStore.subscribe(val => {
    sessionStorage.setItem("chatsStore", val);
});

const nickname = writable(sessionStorage.getItem("nickname") || "");
nickname.subscribe(val => {
    sessionStorage.setItem("nickname", val);
});

const loggedIn = writable(sessionStorage.getItem("loggedIn") || "");
loggedIn.subscribe(val => {
    sessionStorage.setItem("loggedIn", val);
});

const username = writable(sessionStorage.getItem("username") || "");
username.subscribe(val => {
    sessionStorage.setItem("username", val)
});

const eventStore = writable(sessionStorage.getItem("eventStore") || "");

eventStore.subscribe(val => {
    sessionStorage.setItem("eventStore", val)
    setLastRefresh();
}); 

const deviceStore = writable(sessionStorage.getItem("deviceStore") || "");

deviceStore.subscribe(val => { 
    sessionStorage.setItem("deviceStore", val);
    setLastRefresh();
});


const referURL = writable(sessionStorage.getItem("referURL") || "");
referURL.subscribe(val => {
    sessionStorage.setItem("referURL", val);
});



export {
     setLastRefresh,
     lastRefresh,
     eventStore,
     deviceStore,
     username,
     loggedIn,
     nickname,
     chatsStore,
     referURL
 }
 
 
// export const userStore = writable(
//     sessionStorage.getItem("userStore") || "";
// );
// userStore.subscribe(val => sessionStorage.setItem("userStore", val));
