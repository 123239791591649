<script>

    import { Container } from "sveltestrap";
    
    export let bannerVisible;
    
    let bannerText = "Welcome to Prezzence";
  
</script>
<style>

.banner{
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
}
	
</style>

<Container>
    
    <!--Disables warnings in DEV environment-->
    {#if false}<slot></slot>{/if}
    
    
    {#if bannerVisible }
        <div class="m-md-4 p-md-4 text-center">
            <p class="banner">
                <slot name="bannerText">
                    Prezzence
                </slot>
            <p>
                <slot name="bannerSubText">
                    <div class="font-italic">There Are No More Bridges To Be Crossed!</div>
                </slot>
            </p>
        </div>
    {/if}
</Container>
