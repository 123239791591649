<script>

    import { Container }   from "sveltestrap";
	
    import Banner          from "../components/Banner.svelte";
    import Gallery         from "../components/Gallery.svelte";
    import { loggedIn, username, eventStore, deviceStore,lastRefresh } from  "../components/stores.js";
    
    let bannerText = "Welcome to Prezzence";
    let bannerVisible = true;
    
    let api = __prezzence_web.env.api_devices;
    
</script>    

<main>

    <Banner {bannerVisible}>
        <span slot="bannerText">
            All Devices
        </span>
        <span slot="bannerSubText">
            <p>Do you like to play games?</p>
        </span>
    </Banner> 
    
    {#key $loggedIn}
        <Gallery maxItems={8} cat="device" url={api}></Gallery>
    {/key}
    
</main>    
    


<style>
	main {
		text-align: center;
		padding: 1em;
		max-width: 310px;
		margin: 0 auto;
		margin-top: 40px;
	}

	h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>

