<script>


    import Log from '../util/Log.js'
    import { Button, Col, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'sveltestrap';
    import Lazy from './lazy/Lazy.svelte';
    import LightBox from './LightBox.svelte';
    import { onMount, beforeUpdate} from 'svelte';
    import { setContext } from 'svelte';
    import { loggedIn, username, eventStore, deviceStore,lastRefresh } from  "../components/stores.js";

    
    export let maxItems=80;
    export let url = "";
    export let cat = "";
    
    let store = "";

    let previousCat = "";
    let galleryVisible = true;
    let columns = 4;
    let lazyHeight = 300;
    let mLightBox;
    let images = [];
    let titles = [];
    let eventDates = [];
    let items;
    let open = false;
    let start = new Date().getTime(); 
    let end = 0;
    let total = 0    
    
    let thumb = "";
    let timeout = true;
    let refreshPeriod = __prezzence_web.env.gallery_refresh_period;
    
    const logger = new Log(this);   



    async function renderItems() {

        console.log("Gallery Svelte renderItems triggered");

        if(cat=="device"){
            store = $deviceStore; 
        }else{
            store = $eventStore;
        }
        
        if(store===""){
            
            logger.log("Store is \"\"");
            
            items = await getItems();

            if(items!=="undefined"){

                logger.log("Items fetched from server");
                
                store =  JSON.stringify(items);
                
                if(cat=="device"){
                    logger.log("Items saved to deviceStore");
                    $deviceStore = store;
                    logger.log("Items in DeviceStore:"+$deviceStore);
                }else{
                    logger.log("Items saved to eventStore");
                    $eventStore = store;
                    logger.log("Items in EventStore:"+$eventStore); 
                }

            }else{
                logger.log("No images found");
            }
          
            timeout = false;

        }else{
            items =  JSON.parse(store);
            
            let start = new Date().getTime();
            let diff = start-lastRefresh;
            
            if(diff>refreshPeriod){
                deviceStore.set("");
                eventStore.set("");
                logger.log("Gallery: Store reset");
                renderItems();
            }
            
        }
        
        items.map(function(item, index, array) {
            if(index<maxItems){

                images.push(item.images[0]);
                titles.push(item.title);

                if(item.eventDate){
                    eventDates.push(item.eventDate);
                }

            }
        });
        
        return images;
    
    }

    
    async function getItems() {
        try {
            let res = await fetch(url);
            return res.json();
        } catch (error) {
            logger.log(error);
        }   
    }
    
    const openLightBox = thumb => () => {
        mLightBox.setCategory(cat);
        mLightBox.setItems(items.slice(0,maxItems));
        mLightBox.setItemIndex(items.findIndex(evt => evt.images[0] === thumb));
        mLightBox.toggle();
    }
    
	
</script>

<style>

.main {
    min-width: 300px;
}    


</style>

<main>
 
    <LightBox bind:this={mLightBox}></LightBox>

    <Container>
    
    
        
        {#if galleryVisible}
            
            {#await renderItems() }
                
                <p>Loading...</p>
                
            {:then images}
                
                {#each {length: (images.length/columns)} as _, i}
                
                    <Row>
                        
                        {#each {length: columns} as _, n}
                            
                            {#if images.length>((i*columns)+n) }
                               
                                <Col lg="auto">
                                    <div><h3>{ titles.shift() }</h3></div>
                                    <Lazy height={lazyHeight} placeholder="Loading..." >

                                        <a href="/" on:click|preventDefault={openLightBox(thumb)}>
                                            <img alt="" class="card" src={thumb = images.shift()} />  
                                        </a>
                                        {#if cat=="event"}
                                            <p class="alert alert-success"> { eventDates.shift() }</p>
                                        {/if}

                                    </Lazy>
                                </Col>

                            {/if}
                        {/each}  
                    
                    </Row>
                {/each}  
            
            {:catch error}
                <p>Something went wrong... Sorry</p>
            {/await}
            
        {/if}
    
    </Container>
</main>
