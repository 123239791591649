<script lang="ts">
  
  import Log from '../util/Log.js'
  import { createForm } from "svelte-forms-lib";
  import { loggedIn, username, eventStore, deviceStore, referURL } from './stores.js'
  import { Link } from "svelte-routing";  
  import { navigate } from "svelte-routing";
  import * as yup from "yup";
  import {
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    NavItem, 
    NavLink,
    Form, 
    FormGroup, 
    FormText, 
    Input, 
    Label,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Container,
    Row,
    Col
  } from 'sveltestrap';

  let url_validate = __prezzence_web.env.api_auth_validate;
  let url_signoff = __prezzence_web.env.api_auth_signoff;
  let url_get_new_csrf_token = __prezzence_web.env.api_csrf_token;
  
  //Modal 
  let open = false;
  let size ; 
  let result="";
  let welcome = "";
  
  const logger = new Log(this);
  

  //Modal
  const toggle = () => {
    size = undefined;
    result="";
    
    /* Fetch new CSRF-token (todo: timed?) */ 
     if(!open){
         getNewXSRFValue(); 
     }else{
     
        console.log($referURL);
     }    
    
    open = !open;
    
    
  };

  function getCookieXSRFValue(){
        return document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN')).replace("XSRF-TOKEN=","");
  }
 
  
  function getNewXSRFValue(){
   
        fetch(url_get_new_csrf_token)
        .then(response => response.json())
        .then(data =>  {
            $form.token = data.token;
        })
		.catch((error) => {
            logger.log('Error:'+error);
            $form.token = error.token;
            $loggedIn = 0;
            $username = 0;
            $eventStore = "";
            $deviceStore = "";
		});
		
  }
  
  
  function logout(){
        
        welcome ="";
        
        fetch(url_signoff, {
            method: 'POST',
            credentials: 'include',
            headers:{'X-XSRF-TOKEN': getCookieXSRFValue()}
            //body: JSON.stringify({logout:true})
        })
		.then(response => response.json())
		.then(data => {
		
            logger.log('Success:'+data);
            
            if(data.username==""){
                $loggedIn = 0;
                $username = 0;
                $eventStore = "";
                $deviceStore = "";
                $referURL = "";
                location.href= "/";
            } 
			
			
			
		})
		.catch((error) => {
			logger.log('Error:'+error);
			$form.token = error.token;
			$loggedIn = 1;
		});
  };
  
  const { form, errors, state, handleChange, handleSubmit, ErrorMessage, isSubmitting } = createForm({
  //const { form, errors, state, handleChange, handleSubmit, ErrorMessage, isSubmitting } = createForm({
      initialValues: {
            username: "",
            password: ""
      },
      validationSchema: yup.object().shape({
        username: yup.string().required("Required"),
        password: yup.string().required("Required")
      }),
      onSubmit: values => {
 
            logger.log('Login Refer:'+$referURL);
            
            
            fetch(url_validate, {
                method: 'POST',
                credentials: 'include',
                headers:{'X-XSRF-TOKEN': getCookieXSRFValue()},
                body: JSON.stringify(values)
            })
            .then(response => {
                
                if(response.ok){
                    
                    response.json().then(data => {
                    
                        if(response.ok){
                        
                            welcome = "Welcome ".concat(data.username);
                            
                            result = "Authenticated";
 
                            $loggedIn = 1;
                            $username = data.username;

                            
                            //TODO TEST .. looks not ok...sometimes it does...!?
                            //After succesfull login refresh events by removing storage.
                            //sessionStorage.removeItem("events");
                            //$eventStore="";
                            //$deviceStore="";


                            //ORG
                            //toggle();
                            //navigate($referURL, { replace: false });

                            //NEW Gallery reload and stores reset
                            setTimeout(() => {
                                toggle();
                                navigate($referURL, { replace: false });
                             }, 1500);

                        }
                    });
                    
                }else{
                    
                    logger.log('Error:'+response);
                      
                    welcome="";
                    result = "Not authenticated";
                    $loggedIn = 0;
                    $username = 0;
                    
                    throw Error(`Request rejected with status ${response.status}`);
                }
            })
            .catch((error) => {
                //console.error('Error:', error);
                logger.log('Error:'+error);
                welcome="";
                result = "Not authenticated";
                $loggedIn = 0;
                $isSubmitting = false;			
                $username = 0;
            })
            .finally(() => {
                logger.log($state);
            });

      }
  });
    
 function wait(ms){
       var start = new Date().getTime();
       var end = start;
       while(end < start + ms) {
         end = new Date().getTime();
      }
    }
  


</script>

    {#if $loggedIn>0} 
        <Dropdown>
            <!--<DropdownToggle caret>{$loggedIn>0?logout():toggle $loggedIn>0?"Logout":"Login"}</DropdownToggle>-->
            <DropdownToggle caret>{$username}</DropdownToggle>
            <DropdownMenu>
                <DropdownItem disabled>My Events</DropdownItem>
                <DropdownItem disabled>My Devices</DropdownItem>
                <DropdownItem disabled>Settings</DropdownItem>
                <DropdownItem divider />
                <DropdownItem on:click={logout}>Logout</DropdownItem>
            </DropdownMenu> 
        </Dropdown>
    {:else}
        <NavLink href="#" id="navlink-login" on:click={toggle}>Login</NavLink>
    {/if}

  <Modal isOpen={open} {toggle} {size}>
    <ModalHeader {toggle}>Login</ModalHeader>
    <ModalBody align left>
    
        <Container>
            <Row>
                <Col sm="12" md={{ size: 10, offset: 1 }}>
                    <Label>{ welcome }</Label>
                    {#if result }
                        <small>{result}</small>
                    {/if}
                    <Form on:submit={handleSubmit}>
                        <FormGroup>
                            <Label for="name">Username</Label>
                            <Input 
                                readonly={false}
                                type="text"
                                class="input_login"
                                name="username"
                                id="username"
                                placeholder="Username"
                                on:change={handleChange}
                                on:blur={handleChange}
                                bind:value={$form.username}
                            />
                            {#if $errors.username}
                                <small>{$errors.username}</small>
                            {/if}
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                readonly={false}
                                type="password"
                                class="input_login"
                                name="password"
                                id="password"
                                placeholder="Password"
                                on:change={handleChange}
                                on:blur={handleChange}
                                bind:value={$form.password}
                            />
                            {#if $errors.password}
                                <small>{$errors.password}</small>
                            {/if}
                        </FormGroup>
                        <Button color="primary" type="submit" >Login</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    </ModalBody>
  </Modal>


<style>
  
/*    :global(.modal-dialog) {
        width:350px !important; 
    }*/
    
    /*:global(.input_login) {
        width:80% !important;  
    } */
    
    
     
</style>
