<script>

    import { Router, Link, Route, navigate } from "svelte-routing";
    import Home from "./routes/home.svelte";
    import NavBar from "./components/NavBar.svelte";
    import Docs from "./components/Docs.svelte";
    import Event from "./routes/event.svelte";
    import Device from "./routes/device.svelte";
    import DocsRoute from "./routes/docs.svelte";
    import AboutRoute from "./routes/about.svelte";
    import Detail from "./components/Detail.svelte";
    import { onMount } from 'svelte';
    import { eventStore, deviceStore, loggedIn, referURL } from './components/stores.js';
     
    
    export let url = "";
    
    let isOpen = false;

    const color = "light";

    let start = new Date().getTime(); 
    let end = 0;
    let total = 0;

    function handleUpdate(event) {
        isOpen = event.detail.isOpen;
    }
    
    onMount(() => {
	});
    
</script>

<main>
    
    
    
    <Router url="{url}">
        
        <NavBar/>
        
        <Route path="/">
            <Home />
        </Route>
        <Route path="docs" component={DocsRoute} ></Route>
        <Route path="docs/:id" let:params>
            <Docs id="{params.id}"/>
        </Route> 
        <Route path="events" component={Event} ></Route>
        <Route path="event/:id" let:params>
            {#if $loggedIn>0} 
                <Detail id="{params.id}" cat="event"/>
            {:else} 
                { window.location.href="/" }
            {/if}                
        </Route>
        <Route path="devices" component={Device} ></Route>
        <Route path="device/:id" let:params>
            
            {#if $loggedIn>0} 
                <Detail id="{params.id}" cat="device"/>
            {:else} 
                { window.location.href="/" }
            {/if}
        </Route>
        <Route path="about" component={AboutRoute} ></Route>   
        
        
    </Router>
    
</main>    

