<script>
    
    import Log from '../util/Log.js';
    import { loggedIn } from './stores.js'
    import { onMount } from 'svelte';
    import { Input, FormGroup,  Container }   from "sveltestrap";
    import Banner  from "../components/Banner.svelte";
    
    export let id;
    
    let bannerVisible = true;
    const logger = new Log(this);    
    let url = __prezzence_web.env.api_docs;
    
    var data =  {};
    let text;
    
    //const name = id.charAt(0).toUpperCase() + id.slice(1);      
    const name =  id.toUpperCase();
    
    onMount(() => {
        fetchData();
    });
     
    async function fetchData(){
        fetch(url)
        .then(response => response.json())
        .then(result => {
                data = result
                
                if(id=="api"){
                    text = data.API;
                }else if(id=="faq"){
                    text = data.FAQ;
                }else{
                    text = data.MANUAL;
                }
            })
        .catch((error) => {
            console.error('Error:', error);
        });    
    }
        
    
</script>
 
 
<style>

    .inputSearch {
        text-align: center;
    }
     
    .center {
        max-width:200px;
    } 

	main {
		text-align: center;
		padding: 1em;
		max-width: 310px;
		margin: 0 auto;
		margin-top: 40px;
 	}

	h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
        
</style>

 
<main>
    
    <Banner {bannerVisible}>
        <span slot="bannerText">
            {name}
        </span>
        <span slot="bannerSubText">
            <div class="center-block">
                
            </div>
        </span>
    </Banner>
    
    <Container>
        
        {#key $loggedIn}
            <div id="searchText" contenteditable="false" bind:innerHTML={text}></div>
        {/key}
    
    </Container>
    
    
    
</main>
