<script>
     
     
    /*
    
    
    
    */ 
     
    import Log from '../util/Log.js'
    import {Button, Col, Container, Row, Form, FormGroup, FormText, Input, Label } from 'sveltestrap';
	import { nickname, username, chatsStore } from './stores.js';
	import { onMount } from 'svelte';
    import { onDestroy } from 'svelte';
   
    let eb;
    let eventbus_url = __prezzence_web.env.api_eventbus;
     
    const logger = new Log(this);
    
    let sessionChat = "event/chat/event2"
    let saveNickNameButtonText = "Save";
    
    
        
    
    function setupEventBus() {
        
        console.log("Chat Eventbus setup"); 
        eb = new EventBus(eventbus_url, {"vertxbus_ping_interval": 3000}); // sends ping every 1 minute.
        eb.enableReconnect(true);
        
        eb.onclose = function(e){
            //console.log("Chat eb closed");
        };
    
        eb.onopen = function () {
            //console.log("Chat eb opened");
            registerChat();
            checkNickName();
            //console.log("Registered Chat Handler");
        };
                
    }
    
    function buttonChatFiredKeys(event){


        
        switch(event.keyCode){
            case 13:        
                sendChat();
                break;
            default:
                //console.log("KeyCode not implemented: "+event.keyCode);
                break;     
        }
    }
    
    
    
    onMount(() => {

        setupEventBus()
        
        loadChats();
        
        document.addEventListener('keydown', buttonChatFiredKeys, false);
        //console.log(document.EventListener);
        
        //console.log("Chat OnMount: "+ eb);
        
        
	});
	
	onDestroy(() => {
		eb.close();
		//console.log('Chat Component destroyed');
	});
   
    function registerChat(){ 
    
         if(eb){
         
            //console.log("Register Chat Handler: "+sessionChat);
           
            eb.registerHandler(sessionChat, function(error, message) {

                //console.log(message.body);
                 
                let msg="";
                
                if(hasJsonStructure(message.body)){
                    
                    //Save message to ChatStore
                    chatsStore.set($chatsStore+message.body+","); 
                    
                    msg = JSON.parse(message.body);
                    //console.table("Received message JSON: "+ msg);
                    
                    formatChat(msg);
                
                    //console.log("Received message JSON: "+ msg.name);
                    
                }else {
                    msg = message.body;
                    //console.log("Received message non JSON: "+ msg);
                } 
                 
            });
         
         }
        
    }


    
    function loadChats(){
       
       let chatsLoaded=30; 
       let chatsString = $chatsStore;
       let chats =  JSON.parse("["+chatsString.substring(0, chatsString.length - 1)+"]");  
       
       let newChats = chats.slice(chats.length-chatsLoaded, chats.length); 
       newChats.forEach(formatChat);
         
    }
    
    //Json 
    function formatChat(msg){
         
         
        let div = document.createElement("div"); 
        div.innerHTML = "<br><span><b>"+msg.name+"</b><small> says at "+msg.date+"</small></span><br>"+msg.msg; 
        
        chatScroll.prepend(div);
    }
    
    
    function hasJsonStructure(str) {
        if (typeof str !== 'string') return false;
        try {
            const result = JSON.parse(str);
            const type = Object.prototype.toString.call(result);
            return type === '[object Object]' 
                || type === '[object Array]';
        } catch (err) {
            return false;
        }
    }

    function sendChat(){
        
        var inputNickName = document.getElementById("chatNickName");
        var inputChat = document.getElementById("chatInput");
        var chatScroll = document.getElementById("chatScroll");
        
        if($nickname=="" || inputNickName.value==""){
            inputNickName.focus(); 
        }else if(inputChat.value==""){
            inputChat.focus();
        }else{
         
            //In case not saved yet...
            checkNickName(); 
             
            let date = new Date();
            let formattedTime = date.toLocaleTimeString([], { // you can use undefined as first argument
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
            });
            

            let text =  '{"name":"'+inputNickName.value+'","msg":"'+inputChat.value+'","date":"'+formattedTime+'"}';
            
            //console.log(text);
            
            eb.publish(sessionChat,text);
 
            inputChat.value = "";
            inputChat.focus();
            
        }
         
    }
    
    function makeChatUUID() {
        return 'xxxxxxxx'.replace(/[xy]/g, function (a, b) {
                return b = Math.random() * 8, (a == 'y' ? b & 3 | 8 : b | 0).toString(8);
            });
    }
    
    function checkNickName(){
        
        var inputNickName = document.getElementById("chatNickName");
        
        if(inputNickName.value==""){
                inputNickName.readOnly = false;
                saveNickNameButtonText= "Save"; 
                inputNickName.focus();  
        }else{
//             inputNickName.readOnly = true;
//             saveNickNameButtonText= "Edit";
            saveNickName();
        }
            
        if(inputNickName.value!=="" && !inputNickName.readOnly){
            inputNickName.readOnly = true;
            saveNickNameButtonText= "Edit"; 
            //inputNickName.focus(); 
        }

    }
    
    
    function saveNickName(){
 
        var inputNickName = document.getElementById("chatNickName");
        
        if(saveNickNameButtonText == "Edit"){
            inputNickName.readOnly = false;
            saveNickNameButtonText= "Save"; 
            inputNickName.focus();  
        }else {
            if(inputNickName.value==""){
                inputNickName.readOnly = false;
                saveNickNameButtonText= "Save"; 
                inputNickName.focus();  
            }else{
                console.log("Nickname Saved");
                $nickname = inputNickName.value;
                inputNickName.readOnly = true;
                saveNickNameButtonText= "Edit";
            }            
        }
        
    }
    
                    
</script>

     

<style>
 
    body {
        background-color:orange ; 
        color: white;
    }
      
    main {
        
    }
    
    #chatScroll {
        word-wrap: break-word;
    }
     
</style> 

<main>
    
        <div class="input-group mb-3" id="chatDiv">
            <input type="text" class="form-control" id="chatNickName" placeholder="Nickname" value={$username} > 
            <div class="input-group-append">
                <Button on:click={saveNickName} color="secondary" class="btn" type="button" id="saveNickNameButton">{saveNickNameButtonText}</Button>
                <!--<button on:click={setNickName} type="button" id="saveNickNameButton">{saveNickNameButtonText}</button>-->
            </div> 
        </div> 
        <div class="input-group mb-3">
            <input type="text" class="form-control" id="chatInput" maxlength="100"> 
            <div class="input-group-append"> 
                <Button on:click={sendChat} color="primary" class="btn" type="button" id="sendChatButton">Chat</Button>
            </div>
        </div>
         
        <div class="input-group mb-3" id="chatScroll" style="max-width:600px;line-height:1em;display:inline-block; overflow-x:hidden; overflow-y:scroll;"></div>
      
</main>


 
