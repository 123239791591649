
<script>

    import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'sveltestrap';
    import { navigate } from "svelte-routing";
    import Login from './Login.svelte';
    import { loggedIn, username, referURL } from './stores.js'
    
    let open = false;
    let itemIndex = 0;
    let items;
    let cat;
    
    export function setCategory(aCat){
        cat = aCat;
    }
    
    export const toggle = () => {
        open = !open;
    }

    export function setItems(mItems){
        items = mItems;
        //console.log(items);
    }

    export function setItemIndex(n){       
        if (n > items.length-1) {
            itemIndex = 0;
        }else if(n < 0) {
            itemIndex = items.length-1;
        }else {
            itemIndex=n;
        }       
    }

    const openItem = itemIndex => {
        //console.log("openItem for:"+itemIndex);
        toggle();
        
        if($loggedIn>0){
            
            navigate("/"+cat+"/"+items[itemIndex].id, { replace: false });
        }else{
            openLogin(itemIndex);
        }
         
          
    }
        
    const openLogin = itemIndex => {
        
        $referURL = "/"+cat+"/"+items[itemIndex].id ;
        document.getElementById('navlink-login').click();
        
    }
    
</script>



<Modal isOpen={open} {toggle} >
    <ModalHeader {toggle}>Prezzence Game</ModalHeader>
        <ModalBody>
            <div>

                <div class="prev" on:click={setItemIndex(itemIndex-1)}>&#10094;</div>            
                <!--ORG-->
                {#if $loggedIn>0} 
                    <img id="slide" class="slide" on:click={openItem(itemIndex)} src={items[itemIndex].images[0]} alt="Media">
                {:else}
                    <img id="slide" class="slide" on:click={openLogin(itemIndex)} src={items[itemIndex].images[0]} alt="Media">
                {/if}  

                <div class="next" on:click={setItemIndex(itemIndex+1)}>&#10095;</div>
            </div>
            <div>
                <h4>{items[itemIndex].title}</h4>
                <p>{items[itemIndex].description}</p>
            </div>
        </ModalBody>
        <ModalFooter>
            
            {#if $loggedIn>0} 
                <Button color="primary" on:click={openItem(itemIndex)}>Select</Button>
            {/if}  
            
                <Button color="secondary" on:click={toggle}>Close</Button>
            
        </ModalFooter>
</Modal>

<style>

.slide {
    width:100%;
    height:100%;
    cursor:pointer;
}

/* Next & previous buttons */
.prev,
.next {
    /*top: 0%;*/
    /*padding: 16px;*/
    /*margin-top: -20px;*/
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0%;
    width: auto;
    padding-top:50%;
    padding-bottom: 40%;
    margin: 20px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
}

/* Position the "prev button" to the left */
.prev {
    left: 0;
    border-radius: 3px 0 0 3px;
    padding-right: 20%;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
    padding-left: 20%;
    
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
    /*background-color: rgba(255,255,255, 0.0);*/
    visibility:visible;
    color:black;
    opacity: 1;
}


</style>
